<template>
  <div class="contentpanel-layout">
    <div class="contentview-layout bottom-in">
      <div class="contentview-main">
        <div class="search-box" :style="{'width': width}">
          <el-popover v-model="showTable" placement="bottom-start" @show="show" @hide="hide">
            <el-table
              v-loading="tableLoading"
              :data="tableList"
              :max-height="400"
              stripe
              size="mini"
              @row-click="rowClick"
            >
              <el-table-column
                v-for="(item, index) in props"
                :key="index"
                :label="item['label']"
                :prop="item['prop']"
                :width="item['prop']||''"
                :min-width="item['minWidth']||''"
                :fixed="item['fixed']||''"
                :formatter="item['formatter']"
                align="center"
                resizable
              />
            </el-table>
            <el-pagination
              small
              layout="prev, pager, next"
              :hide-on-single-page="true"
              :page-size="pageSize"
              :pager-count="5"
              :total="total"
              @current-change="currentChange"
            />
            <div slot="reference">
              <el-input id="inputRes" v-model="inputValue" size="small" :placeholder="placeholder" @input="input" />
            </div>
          </el-popover>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'CustomFuzzySearch',
  props: {
    props: {
      type: Array,
      default: () => []
    },
    searchValue: {
      type: [String, Number],
      required: true
    },
    searchField: {
      type: String,
      required: true
    },
    showField: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: '请选中搜索结果'
    },
    width: {
      type: String,
      default: '100%'
    }
  },
  data() {
    return {
      inputValue: '',
      showTable: false,
      tableLoading: false,
      tableList: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      inputSetTime: null
    }
  },
  watch: {
    searchValue() {
      if (!this.searchValue) {
        Object.assign(this, this.$options.data.call(this))
      }
    }
  },
  methods: {
    show() {
      this.tableLoading = true
      this.input(this.inputValue)
    },
    hide() {
      if (document.activeElement.id === 'inputRes') {
        this.showTable = true
      }
    },
    getData() {
      this.tableLoading = true
      return new Promise(resolve => {
        const param = {
          current: this.currentPage,
          size: this.pageSize,
          param: {
            [this.searchField]: this.inputValue
          }
        }
        this.$emit('searchData', param, res => {
          if (res.code !== 200) {
            this.total = 0
            this.tableList = []
            setTimeout(() => { this.tableLoading = false }, 200)
            this.$message.error(res.msg)
            return
          }
          this.total = res.data.total
          this.tableList = res.data.records
          setTimeout(() => { this.tableLoading = false }, 200)
          resolve()
        })
      })
    },
    input(value, page = 1) {
      this.currentPage = page
      if (value === '') {
        this.$emit('searchUpdate')
      }
      this.inputValue = value
      if (this.inputSetTime !== null) {
        clearTimeout(this.inputSetTime)
        this.inputSetTime = null
      }
      this.inputSetTime = setTimeout(() => {
        this.getData().then(() => {
          this.inputSetTime = null
        })
      }, 500)
    },
    currentChange(page) {
      this.input(this.inputValue, page)
    },
    rowClick(row) {
      this.$emit('searchChange', row)
      this.inputValue = row[this.showField]
      this.showTable = false
      this.input(this.inputValue)
    }
  }
}
</script>

<style scoped lang="scss">
  ::v-deep .el-table__row {
    cursor: pointer;
  }
</style>
