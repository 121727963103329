<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button v-permission="'ConfigMiniappBannerAddButton'" status="my-orange" icon="fa fa-plus" @click="handleAdd()">添加</vxe-button>
      </template>
    </vxe-toolbar>
    <CustomList ref="CustomList" :columns="tableColumns" @getList="getList">
      <CustomPic slot="photo" slot-scope="{row}" :src="row.photo || ''" :src-list="[row.photo]" />
      <template slot="newsTitle" slot-scope="{row}">
        <el-link v-if="row.news" type="primary" :underline="false" @click="handleNewDetail(row.news.id)">
          {{ row.news.newTile }}
        </el-link>
      </template>
      <template slot="operating" slot-scope="{row}">
        <el-button v-permission="'ConfigMiniappBannerEditButton'" size="small" type="text" @click="handleEdit(row.id)">编辑</el-button>
        <el-button v-permission="'ConfigMiniappBannerDeleteButton'" size="small" type="text" @click="handleDelete(row.id)">删除</el-button>
      </template>
    </CustomList>
    <Form ref="Form" @submit="customListGetList(true)" />
    <NewForm ref="NewForm" />
  </div>
</template>

<script>
import CustomList from '@/components/CustomList/index'
import { default as CustomTemplate } from '@/components/CustomList/CustomTemplate'
import { mapActions } from 'vuex'
import Form from './components/form'
import { default as NewForm } from '@/views/Pages/news/news/components/form'
export default {
  name: 'MiniappMenu',
  components: { Form, NewForm, CustomList, ...CustomTemplate },
  data() {
    return {
      tableColumns: [
        {
          title: '图片',
          slot: 'photo'
        },
        {
          title: '跳转新闻',
          slot: 'newsTitle'
        },
        {
          title: '操作',
          fixed: 'right',
          slot: 'operating'
        }
      ]
    }
  },
  methods: {
    ...mapActions(['miniappBannerPublicPageList', 'miniappBannerDelete']),
    customListGetList(resetCurrentPage = false) {
      this.$refs.CustomList.getList(resetCurrentPage)
    },
    getList(params, callback) {
      params['hasTenant'] = true
      this.miniappBannerPublicPageList(params).then(response => {
        response.data = {
          records: response.data
        }
        callback(response)
      }).catch(() => {})
    },
    handleAdd() {
      this.$refs.Form.handleAdd()
    },
    handleEdit(id) {
      this.$refs.Form.handleEdit(id)
    },
    handleNewDetail(id) {
      this.$refs.NewForm.handleDetail(id)
    },
    handleDelete(id) {
      this.$confirm('确认删除吗？', '', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.miniappBannerDelete({ id }).then(res => {
          if (res.code !== 200) {
            this.$message.error(res.msg)
            return
          }
          this.$message.success('删除成功')
          this.customListGetList(true)
        }).catch(() => {})
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper{
    overflow: auto;
  }
</style>
