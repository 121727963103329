<template>
  <CustomDrawer
    ref="CustomDrawer"
    :title="title"
    @openedCallback="openedCallback"
    @handleConfirm="handleConfirm"
  >
    <CustomForm
      ref="CustomForm"
      :form-model="formModel"
      :form-items="formItem"
      :form-rules="formRules"
    >
      <UploadImg slot="photoId" slot-scope="{item}" :upload-lists="photoUploadLists" @uploadChange="item.uploadChange" />
      <CustomFuzzySearch
        slot="newsId"
        ref="newsFuzzySearch"
        search-field="newTile"
        show-field="newTile"
        :props="newsFuzzySearchProps"
        :search-value="formModel['newsId']"
        @searchChange="formModel['newsId']=$event.id"
        @searchUpdate="formModel['newsId']=''"
        @searchData="newsFuzzySearchData"
      />
    </CustomForm>
  </CustomDrawer>
</template>

<script>
import CustomDrawer from '@/components/CustomDrawer/index'
import CustomForm from '@/components/CustomForm/index'
import { mapActions } from 'vuex'
import UploadImg from '@/components/UploadImg'
import CustomFuzzySearch from '@/components/CustomFuzzySearch/index'
export default {
  name: 'Form',
  components: { CustomFuzzySearch, UploadImg, CustomDrawer, CustomForm },
  data() {
    return {
      title: '',
      formId: '',
      formStatus: '',
      photoUploadLists: [],
      formModel: {
        photoId: '',
        newsId: ''
      },
      newsFuzzySearchProps: [
        {
          'prop': 'categoryName',
          'label': '分类'
        },
        {
          'prop': 'subCategoryName',
          'label': '子分类'
        },
        {
          'prop': 'newTile',
          'label': '标题'
        }
      ]
    }
  },
  computed: {
    formItem() {
      return {
        'photoId': {
          'elColSpan': 24,
          'label': '图片',
          'slot': 'photoId',
          'uploadChange': data => {
            if (data.length) {
              this.$set(this.formModel, 'photoId', data[0])
            } else {
              this.$set(this.formModel, 'photoId', '')
            }
          }
        },
        'newsId': {
          'elColSpan': 24,
          'label': '跳转新闻',
          'slot': 'newsId'
        }
      }
    },
    formRules() {
      return {
        photoId: [
          { required: true, message: '图片不能为空', trigger: ['blur', 'change'] }
        ],
        newsId: [
          { required: true, message: '跳转新闻不能为空', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  methods: {
    ...mapActions(['newsPublicPageList', 'miniappBannerPublicDetail', 'miniappBannerAdd', 'miniappBannerUpdate']),
    newsFuzzySearchData(param, cb) {
      param['hasTenant'] = true
      this.newsPublicPageList(param).then(res => {
        cb(res)
      }).catch(() => {})
    },
    handleAdd() {
      this.init('add')
    },
    handleEdit(id) {
      this.init('edit', id)
    },
    init(formStatus, id = '') {
      Object.assign(this, this.$options.data.call(this))
      this.$refs.CustomDrawer.handleOpen()
      this.formId = id
      this.formStatus = formStatus
      switch (formStatus) {
        case 'add':
          this.title = '添加'
          break
        case 'edit':
          this.title = '编辑'
          break
        default:
          this.title = '未知'
          break
      }
    },
    openedCallback() {
      this.initData().then(() => {
        this.$nextTick(() => {
          if (this.formModel.news) {
            this.$refs.newsFuzzySearch.rowClick(this.formModel.news)
          }
        })
        this.$refs.CustomForm.$refs.form.clearValidate()
        this.$refs.CustomDrawer.closeLoading()
      }).catch(() => {
        this.$refs.CustomDrawer.handleClose()
      })
    },
    initData() {
      return new Promise((resolve, reject) => {
        if (this.formStatus !== 'add') {
          this.miniappBannerPublicDetail({ id: this.formId }).then(res => {
            if (res.code !== 200) {
              this.$message.error(res.msg)
              reject()
              return
            }

            this.photoUploadLists = [
              {
                'id': res.data.photoId,
                'url': res.data.dfsUrl
              }
            ]

            this.formModel.id = this.formId
            this.formModel.photoId = res.data.photoId
            this.formModel.newsId = res.data.newsId
            this.formModel.news = res.data.news
            resolve()
          }).catch(() => {
            reject()
          })
        } else {
          resolve()
        }
      })
    },
    handleConfirm() {
      this.$refs.CustomDrawer.openLoading()
      this.$refs.CustomForm.$refs.form.validate(res => {
        if (res) {
          const params = { ...this.formModel }
          switch (this.formStatus) {
            case 'add':
              delete params.id
              this.miniappBannerAdd(params).then(res => {
                if (res.code !== 200) {
                  this.$refs.CustomDrawer.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('添加成功')
                this.$emit('submit')
                this.$refs.CustomDrawer.handleClose()
              }).catch(() => {
                this.$refs.CustomDrawer.closeLoading()
              })
              break
            case 'edit':
              this.miniappBannerUpdate(params).then(res => {
                if (res.code !== 200) {
                  this.$refs.CustomDrawer.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('修改成功')
                this.$emit('submit')
                this.$refs.CustomDrawer.handleClose()
              }).catch(() => {
                this.$refs.CustomDrawer.closeLoading()
              })
              break
            default:
              this.$refs.CustomDrawer.handleClose()
              break
          }
        } else {
          this.$refs.CustomDrawer.closeLoading()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
